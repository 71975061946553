import { ShippingDetails } from '@menesko/models-harmonia';
import { forwardRef } from 'react';

import { useAppLead } from '~/hooks/useAppLead';
import { useAppRouter } from '~/hooks/useAppRouter';
import { useCookies } from '~/hooks/useCookies';
import { useLeadCreateMutation } from '~/hooks/useLeadCreateMutation';
import { useLeadUpdateMutation } from '~/hooks/useLeadUpdateMutation';

import { ShippingForm, ShippingFormProps } from './shipping-form';

export const LeadShippingForm = forwardRef<HTMLFormElement, ShippingFormProps>(
  ({ error, isLoading, onSubmit, ...props }, ref) => {
    const cookies = useCookies();
    const { funnelName } = useAppRouter();
    const { id: leadId } = useAppLead();
    const { mutateAsync: update, isLoading: isUpdateLoading, error: errorUpdate } = useLeadUpdateMutation();

    const { mutateAsync: create, isLoading: isCreateLoading, error: errorCreate } = useLeadCreateMutation();

    const handleSubmit = async (shippingDetails: ShippingDetails) => {
      if (leadId) {
        await update({
          id: leadId,
          shippingDetails,
        });
      } else {
        await create({
          email: shippingDetails.email,
          shippingDetails,
          customFields: {
            ...cookies.getCustomFields(),
            funnel: funnelName,
          },
        });
      }

      onSubmit(shippingDetails);
    };

    return (
      <ShippingForm
        ref={ref}
        onSubmit={handleSubmit}
        isLoading={isUpdateLoading || isCreateLoading || isLoading}
        error={errorUpdate || errorCreate || error}
        {...props}
      />
    );
  },
);

LeadShippingForm.displayName = 'LeadShippingForm';
