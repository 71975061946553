import { Flex, StyleProps, Text } from '@chakra-ui/react';
import { OrderItem } from '@menesko/models-harmonia';
import { generalUtils } from '@shared/common';
import { ForwardedRef, forwardRef, Fragment, useMemo } from 'react';

import { useCheckout } from '~/hooks/useCheckout';
import { useProducts } from '~/hooks/useProducts';

type OrderSummaryProps = {
  orderItems: OrderItem[];
  padding?: StyleProps['padding'];
  borderRadius?: StyleProps['borderRadius'];
  border?: StyleProps['border'];
  marginBottom?: StyleProps['marginBottom'];
};

export const OrderSummary = forwardRef((props: OrderSummaryProps, ref: ForwardedRef<HTMLDivElement>) => {
  const { currency } = useCheckout();
  const { findProductById, findProductPrice } = useProducts();
  const { orderItems, ...rest } = props;

  const totalPrice = useMemo(() => {
    return orderItems.reduce((sum, item) => sum + item.amount, 0);
  }, [orderItems]);

  return totalPrice ? (
    <Flex gap='0.25rem' flexDir='column' {...rest} ref={ref}>
      {orderItems.map((orderItem, index) => {
        const finalPrice = orderItem.amount;
        const product = findProductById(orderItem.productId);
        const productPrice = findProductPrice(product, currency);
        const price = productPrice?.price || 0;

        return (
          <Fragment key={index}>
            <Flex justifyContent='space-between' color='black90'>
              <Text>{product?.name}</Text>
              <Text>{generalUtils.formatPrice(price, currency)}</Text>
            </Flex>
            <Flex
              justifyContent='space-between'
              color='error'
              marginBottom='0.75rem'
              paddingBottom='0.75rem'
              borderBottom='1px solid rgba(0, 0, 0, 0.1)'
            >
              <Text>The Special Discount (-{(100 - (finalPrice / price) * 100).toFixed(0)}%)</Text>
              <Text>-{generalUtils.formatPrice(price - finalPrice, currency)}</Text>
            </Flex>
          </Fragment>
        );
      })}
      <Flex justifyContent='space-between' color='black90'>
        <Text>Shipping</Text>
        <Text>{generalUtils.formatPrice(15, currency)}</Text>
      </Flex>
      <Flex justifyContent='space-between' color='error'>
        <Text>Discount (-100%)</Text>
        <Text>-{generalUtils.formatPrice(15, currency)}</Text>
      </Flex>
      <Flex
        justifyContent='space-between'
        paddingTop='0.75rem'
        borderTop='1px solid rgba(0, 0, 0, 0.1)'
        marginTop='0.75rem'
      >
        <Text fontFamily='heading' fontSize='24px'>
          Total:
        </Text>
        <Text fontFamily='heading' fontSize='24px'>
          {generalUtils.formatPrice(totalPrice, currency)}
        </Text>
      </Flex>
    </Flex>
  ) : null;
});

OrderSummary.displayName = 'OrderSummary';
