import { Box, CloseButton, Flex, Text } from '@chakra-ui/react';
import { AppRoute } from '@shared/common';

import { useAppRouter } from '~/hooks/useAppRouter';
import { LeadShippingForm } from '~/layouts/shipping/lead-shipping-form';

export const ShippingModal = () => {
  const { pushNextRoute, back } = useAppRouter(AppRoute.CHECKOUT);

  const handleSubmit = () => {
    pushNextRoute();
  };

  return (
    <Box width='100%'>
      <Flex justifyContent='right' marginBottom='1.5rem'>
        <CloseButton onClick={back} color='black' border='none' />
      </Flex>
      <Flex alignItems='center' marginBottom='0.75rem'>
        {/* <CheckCircleSVG fill={colors.primary} stroke={colors.primary} /> */}
        <Flex width='1.5rem' height='1.5rem' border='2px solid' borderColor='primary' borderRadius='2rem' />
        <Box flex={1} height='1px' bg='black10' />
        <Flex width='1.5rem' height='1.5rem' border='2px solid' borderColor='black10' borderRadius='2rem' />
        <Box flex={1} height='1px' bg='black10' />
        <Flex width='1.5rem' height='1.5rem' border='2px solid' borderColor='black10' borderRadius='2rem' />
      </Flex>
      <Flex justifyContent='space-between' marginBottom='1.5rem'>
        <Text fontSize='12px' fontWeight={600} color='black60'>
          SHIPPING
        </Text>
        <Text fontSize='12px' fontWeight={600} color='black60'>
          PAYMENT
        </Text>
        <Text fontSize='12px' fontWeight={600} color='black60'>
          RECEIPT
        </Text>
      </Flex>
      <Text
        textAlign='center'
        fontSize={{ base: '24px', md: '32px' }}
        fontFamily='heading'
        marginBottom={{ base: '1.5rem', md: '1.5rem' }}
      >
        Enter your shipping details
      </Text>
      <LeadShippingForm onSubmit={handleSubmit} />
    </Box>
  );
};
