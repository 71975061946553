import { Alert, AlertIcon, AlertDescription, Box, Button, Flex, Text } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { emailSchema } from '@menesko/models-harmonia';
import { memo } from 'react';
import { DefaultValues, useForm } from 'react-hook-form';
import { InferType, object } from 'yup';

import SafeWebSVG from '~/assets/icons/safe_web.svg';
import { sleep } from '~/helpers/sleep';
import { useAppLead } from '~/hooks/useAppLead';
import { useAppRouter } from '~/hooks/useAppRouter';
import { useCookies } from '~/hooks/useCookies';
import { useLeadCreateMutation } from '~/hooks/useLeadCreateMutation';
import { AppRoute } from '~/types';

import { Arrow } from '../Arrow';
import { InputControl } from '../form/input';

const schema = object().shape({
  email: emailSchema,
});

type FormData = InferType<typeof schema>;

export const EmailForm = memo(() => {
  const cookies = useCookies();
  const { email } = useAppLead();
  const { pushNextFunnelRoute, funnelName } = useAppRouter(AppRoute.LOADER);
  const {
    mutate: leadMutation,
    isLoading,
    error,
  } = useLeadCreateMutation({
    onSuccess: () => {
      pushNextFunnelRoute();
    },
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
    control,
  } = useForm<FormData>({
    resolver: yupResolver(schema),
    defaultValues: {
      email,
    } as DefaultValues<FormData>,
  });

  const { quizAnswers } = useAppLead();

  const onSubmit = async (data: FormData) => {
    await sleep(500);

    await leadMutation({
      email: data.email,
      quizAnswers,
      customFields: {
        ...cookies.getCustomFields(),
        funnel: funnelName,
      },
    });
  };

  return (
    <Box as='form' width='100%' onSubmit={handleSubmit(onSubmit)} maxW='32rem'>
      <InputControl
        name='email'
        control={control}
        inputProps={{ placeholder: 'Your email address' }}
        marginBottom='1rem'
      />
      {error ? (
        <Alert status='error' marginBottom='1rem' title='Error'>
          <AlertIcon />
          <AlertDescription>{error.message}</AlertDescription>
        </Alert>
      ) : null}
      <Flex gap='0.75rem' alignItems='center' marginBottom='1.5rem'>
        <Flex>
          <SafeWebSVG />
        </Flex>
        <Text fontSize='13px' color='black50'>
          We don&apos;t send spam or share email addresses. We respect your privacy.
        </Text>
      </Flex>
      <Flex flexDir='column' gap='0.5rem' alignItems='center'>
        <Button type='submit' isLoading={isLoading || isSubmitting}>
          Continue <Arrow dir='right' fill='white' />
        </Button>
        <Box display='inline-block'>
          <Box
            width={0}
            height={0}
            borderLeft='5px solid transparent'
            borderRight='5px solid transparent'
            borderBottom='5px solid black'
            margin='auto'
          />
          <Box bg='black' padding='0.5rem 1rem' borderRadius='0.5rem'>
            <Text fontSize='12px' color='white' lineHeight='125%'>
              SALE UP TO 65% OFF
            </Text>
          </Box>
        </Box>
      </Flex>
    </Box>
  );
});

EmailForm.displayName = 'EmailForm';
