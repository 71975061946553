import { Text, Box, Flex, Button } from '@chakra-ui/react';
import { Order, OrderShippingStatus, OrderStatus } from '@menesko/models-harmonia';
import { formatInTimeZone } from 'date-fns-tz';
import { forwardRef, ForwardedRef } from 'react';

import { OrderSummary } from '~/layouts/OrderSummary';

import { ProgressDot } from '../../ProgressDot';

import { getTrackingUrl } from './utils';

const cardStyles = {
  padding: { base: '1rem', md: '1.5rem' },
  borderRadius: '0.5rem',
  border: '1.5px solid black',
};

export const OrderFound = forwardRef(({ order, ...rest }: { order: Order }, ref: ForwardedRef<HTMLDivElement>) => {
  const orderStatusIndexMap: Record<string, number> = {
    [OrderStatus.CREATED]: 0,
    [OrderStatus.PAID]: 1,
    [OrderShippingStatus.PENDING]: 1,
    [OrderShippingStatus.AWAITING]: 1,
    [OrderShippingStatus.SHIPPED]: 2,
  };

  const orderStatusIndex = order.shippingStatus
    ? orderStatusIndexMap[order.shippingStatus]
    : orderStatusIndexMap[order.status];

  const isShipped = order.shippingStatus === OrderShippingStatus.SHIPPED;

  const openTrackingUrl = () => {
    if (!order.trackingNumber || !order.carrier) {
      return;
    }

    const trackingUrl = getTrackingUrl({
      carrier: order.carrier,
      trackingNumber: order.trackingNumber,
    });

    window.open(trackingUrl, '_blank');
  };

  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const orderDate = formatInTimeZone(order.createdAt, timeZone, 'PPP');

  const { firstName, lastName, email, phone, addressLine1, addressLine2, state, city, zip } =
    order.shippingDetails || {};

  return (
    <>
      <Text fontFamily='heading' fontSize='36px' marginTop='3rem' marginBottom='1rem'>
        Thanks for your order!
      </Text>
      <Box {...rest} ref={ref}>
        <Box {...cardStyles} marginBottom='1rem'>
          <Text fontSize='24px' marginBottom='0.5rem'>
            Order: {order.orderNumber}
          </Text>
          <Text marginBottom='2rem'>
            <Text as='span' color='gray.500'>
              Order date:
            </Text>{' '}
            {orderDate}
          </Text>
          <ProgressDot items={['RECEIVED', 'CONFIRMED', 'SHIPPED']} activeIndex={orderStatusIndex} />
          {isShipped ? (
            <Flex direction='column' marginTop='2rem'>
              <Button size='md' boxShadow='none' onClick={openTrackingUrl}>
                Track order
              </Button>
              <Text fontSize='12px' color='black60' marginTop='0.5rem' textAlign='center'>
                Tracking number:{' '}
                <Text as='span' textDecoration='underline' cursor='pointer' onClick={openTrackingUrl}>
                  {order.trackingNumber}
                </Text>
              </Text>
            </Flex>
          ) : null}
        </Box>
        <Box {...cardStyles} marginBottom='1rem'>
          <Text fontFamily='heading' fontSize='24px' marginBottom='0.5rem'>
            Shipping
          </Text>
          <Text marginBottom='0.1rem' color='black.90'>
            {firstName} {lastName}
          </Text>
          <Text marginBottom='0.1rem' color='black.90'>
            {addressLine1} {addressLine2}
          </Text>
          <Text marginBottom='0.1rem' color='black.90'>
            {city}, {state}, {zip}
          </Text>
          <Text marginBottom='0.1rem' color='black.90'>
            {phone}
          </Text>
          <Text marginBottom='0.1rem' color='black.90'>
            {email}
          </Text>
        </Box>
        <OrderSummary {...cardStyles} orderItems={order.items} />
      </Box>
    </>
  );
});

OrderFound.displayName = 'OrderFound';
