import { Button, Text, Grid, Box, Flex, Spinner } from '@chakra-ui/react';
import { Product } from '@menesko/models-harmonia';
import { generalUtils } from '@shared/common';
import Image from 'next/image';
import { useState } from 'react';

import CheckSVG from '~/assets/icons/check.svg';
import Product1Image from '~/assets/images/products/cocktail_1.png';
import Product3Image from '~/assets/images/products/cocktail_3.png';
import Product6Image from '~/assets/images/products/cocktail_6.png';
import { useAppLead } from '~/hooks/useAppLead';
import { useAppRouter } from '~/hooks/useAppRouter';
import { useProducts } from '~/hooks/useProducts';
import { useTracking } from '~/hooks/useTracking';
import { AppRoute } from '~/types';

interface CortisolCocktailToggleProps {
  singleColumn?: boolean;
}

export const CortisolCocktailToggle = ({ singleColumn = false }: CortisolCocktailToggleProps) => {
  const { selectedPlan, updateLead } = useAppLead();
  const { mainProducts } = useProducts();
  const { plansSelect } = useTracking();
  const { pushNextFunnelRoute } = useAppRouter(AppRoute.CHECKOUT);
  const [isSubscription, setIsSubscription] = useState(true);
  const [isSubmited, setIsSubmited] = useState(false);

  const handleSelection = (product: Product) => {
    setIsSubmited(true);

    updateLead({ selectedPlan: product });

    plansSelect({
      category: product.key,
      label: product.title,
    });

    setTimeout(() => {
      pushNextFunnelRoute();
    }, 1000);
  };

  return (
    <Flex width='100%' flexDir='column' alignItems='center'>
      <Flex
        bg='#F4DCC64D'
        padding='0.25rem'
        marginBottom={singleColumn ? '1rem' : { base: '1rem', md: '3rem' }}
        borderRadius='full'
        gap='0.25rem'
        justifyContent='center'
        alignItems='center'
      >
        <Text
          bg={isSubscription ? undefined : 'white'}
          lineHeight='1.3'
          fontSize={singleColumn ? '14px' : { base: '14px', md: '16px' }}
          padding='0.75rem 1.25rem'
          borderRadius='full'
          fontWeight={600}
          boxShadow={isSubscription ? undefined : '0 1px 3px #3A271D40'}
          cursor='pointer'
          onClick={() => setIsSubscription(false)}
        >
          One-time purchase
        </Text>
        <Flex
          bg={isSubscription ? 'white' : undefined}
          padding='0.75rem 1.25rem'
          borderRadius='full'
          gap='0.5rem'
          alignItems='center'
          boxShadow={isSubscription ? '0 1px 3px #3A271D40' : undefined}
          cursor='pointer'
          onClick={() => setIsSubscription(true)}
        >
          <Text fontSize={singleColumn ? '14px' : { base: '14px', md: '16px' }} lineHeight='1.3' fontWeight={600}>
            Subscribe
          </Text>
          <Text
            bg='error'
            padding='0.25rem 0.625rem'
            borderRadius='8px'
            lineHeight='1.3'
            color='white'
            fontSize={singleColumn ? '12px' : { base: '12px', md: '13px' }}
            fontWeight={600}
          >
            SALE%
          </Text>
        </Flex>
      </Flex>
      <Grid
        width='100%'
        gap='1rem'
        gridTemplateColumns={singleColumn ? 'repeat(1, 1fr)' : { base: 'repeat(1, 1fr)', md: 'repeat(3, 1fr)' }}
      >
        {(() => {
          const product = mainProducts[1][isSubscription ? 'sub' : 'main'];
          const priceObject = product?.prices?.find((p) => p.currency === 'USD');
          const finalPrice = priceObject?.finalPrice || 64.99;
          const price = priceObject?.price || 64.99;
          const pricePerDay = Math.floor((finalPrice / 30) * 100) / 100;
          const isLoading = isSubmited && selectedPlan?.id === product?.id;

          return product ? (
            <Box
              position='relative'
              padding='1.5rem'
              borderRadius='1rem'
              border='1.5px solid'
              borderColor='black10'
              bg='#F4DCC61A'
              order={singleColumn ? 3 : { base: 3, md: 0 }}
            >
              <Flex
                flexDir={singleColumn ? 'row' : { base: 'row', md: 'column-reverse' }}
                marginBottom='1rem'
                gap='1rem'
              >
                <Box flex={1}>
                  <Flex alignItems='baseline' gap='6px'>
                    <Text lineHeight='100%' fontFamily='heading' fontSize='32px'>
                      {generalUtils.formatPrice(finalPrice, 'USD')}
                    </Text>
                    <Text fontSize='12px' color='black40'>
                      per bag
                    </Text>
                  </Flex>
                  <Text
                    marginBottom='0.5rem'
                    paddingBottom='0.5rem'
                    borderBottom='1px solid #F0EDEB'
                    color='error'
                    textDecor='line-through'
                  >
                    {generalUtils.formatPrice(price, 'USD')}
                  </Text>
                  <Flex flexDir='column' gap='0.25rem'>
                    <Flex alignItems='center' gap='0.5rem'>
                      <Flex>
                        <CheckSVG />
                      </Flex>
                      <Text fontSize='14px'>30 servings</Text>
                    </Flex>
                    <Flex alignItems='center' gap='0.5rem'>
                      <Flex>
                        <CheckSVG />
                      </Flex>
                      <Text fontSize='14px'>{`${generalUtils.formatPrice(pricePerDay, 'USD')} per day`}</Text>
                    </Flex>
                    <Flex alignItems='center' gap='0.5rem'>
                      <Flex>
                        <CheckSVG />
                      </Flex>
                      <Text fontSize='14px'>1 bag delivered</Text>
                    </Flex>
                  </Flex>
                </Box>
                <Box flex={1} alignItems='flex-start' textAlign='center'>
                  <Text fontWeight={500}>1-month supply</Text>
                  <Text fontSize='12px' color='#22222280'>
                    Ideal solution for trying out
                  </Text>
                  <Flex
                    position='relative'
                    width='100%'
                    paddingBottom={singleColumn ? '70%' : { base: '70%', md: '90%' }}
                  >
                    <Image src={Product1Image} fill priority placeholder='blur' alt='' objectFit='contain' />
                  </Flex>
                </Box>
              </Flex>
              <Button
                variant='black'
                justifyContent={isLoading ? 'center' : 'space-between'}
                fontWeight={600}
                onClick={() => handleSelection(product)}
                marginBottom='1rem'
                isLoading={isLoading}
              >
                <div style={{ width: '11px' }} /> Order now <ArrowSVG />
              </Button>
              <Text fontSize='12px' textAlign='center'>
                {isSubscription ? 'Cancel anytime' : 'One time payment'}. Free shipping
              </Text>
            </Box>
          ) : (
            <Flex justifyContent='center'>
              <Spinner />
            </Flex>
          );
        })()}
        {(() => {
          const product = mainProducts[3][isSubscription ? 'sub' : 'main'];
          const priceObject = product?.prices?.find((p) => p.currency === 'USD');
          const finalPrice = (priceObject?.finalPrice || 43.99) / 3;
          const price = (priceObject?.price || 43.99) / 3;
          const pricePerDay = Math.floor((finalPrice / 30) * 100) / 100;
          const isLoading = isSubmited && selectedPlan?.id === product?.id;

          return product ? (
            <Box
              position='relative'
              padding='1.5rem'
              borderRadius='1rem'
              border='1.5px solid'
              borderColor='green'
              bg='white'
              boxShadow={
                singleColumn ? 'none' : { base: 'none', md: '0px 6px 14px 0px #3A271D14, 0px 25px 25px 0px #3A271D12' }
              }
              order={singleColumn ? 2 : { base: 2, md: 0 }}
            >
              <Flex
                flexDir={singleColumn ? 'row' : { base: 'row', md: 'column-reverse' }}
                marginBottom='1rem'
                gap='1rem'
              >
                <Box flex={1}>
                  <Flex
                    position={singleColumn ? 'static' : { base: 'static', md: 'absolute' }}
                    top='-12px'
                    left={0}
                    right={0}
                    marginBottom={singleColumn ? '0.5rem' : { base: '0.5rem', md: 0 }}
                    justifyContent={singleColumn ? 'initial' : { base: 'initial', md: 'center' }}
                  >
                    <Text
                      display='inline-block'
                      color='white'
                      fontSize={singleColumn ? '12px' : { base: '12px', md: '15px' }}
                      lineHeight='130%'
                      bg='green'
                      padding={singleColumn ? '3px 9px' : { base: '3px 9px', md: '4px 1rem' }}
                      borderRadius='0.5rem'
                    >
                      Most popular SAVE {isSubscription ? '50%' : '40%'}
                    </Text>
                  </Flex>

                  <Flex alignItems='baseline' gap='6px'>
                    <Text lineHeight='100%' fontFamily='heading' fontSize='32px'>
                      {generalUtils.formatPrice(finalPrice, 'USD')}
                    </Text>
                    <Text fontSize='12px' color='black40'>
                      per bag
                    </Text>
                  </Flex>
                  <Text
                    marginBottom='0.5rem'
                    paddingBottom='0.5rem'
                    borderBottom='1px solid #F0EDEB'
                    color='error'
                    textDecor='line-through'
                  >
                    {generalUtils.formatPrice(price, 'USD')}
                  </Text>
                  <Flex flexDir='column' gap='0.25rem'>
                    <Flex alignItems='center' gap='0.5rem'>
                      <Flex>
                        <CheckSVG />
                      </Flex>
                      <Text fontSize='14px'>90 servings</Text>
                    </Flex>
                    <Flex alignItems='center' gap='0.5rem'>
                      <Flex>
                        <CheckSVG />
                      </Flex>
                      <Text fontSize='14px'>{`${generalUtils.formatPrice(pricePerDay, 'USD')} per day`}</Text>
                    </Flex>
                    <Flex alignItems='center' gap='0.5rem'>
                      <Flex>
                        <CheckSVG />
                      </Flex>
                      <Text fontSize='14px'>3 bags delivered</Text>
                    </Flex>
                  </Flex>
                </Box>
                <Box flex={1} textAlign='center'>
                  <Text fontWeight={500}>3-month supply</Text>
                  <Text fontSize='12px' color='#22222280'>
                    Great for building new habits
                  </Text>
                  <Flex
                    width='100%'
                    position='relative'
                    paddingBottom={singleColumn ? '70%' : { base: '70%', md: '90%' }}
                  >
                    <Image src={Product3Image} fill priority placeholder='blur' alt='' objectFit='contain' />
                  </Flex>
                </Box>
              </Flex>
              <Button
                variant='black'
                justifyContent={isLoading ? 'center' : 'space-between'}
                fontWeight={600}
                onClick={() => handleSelection(product)}
                marginBottom='1rem'
                isLoading={isLoading}
              >
                <div style={{ width: '11px' }} /> Order now <ArrowSVG />
              </Button>
              <Text fontSize='12px' textAlign='center'>
                {isSubscription ? 'Cancel anytime' : 'One time payment'}. Free shipping
              </Text>
            </Box>
          ) : (
            <Flex justifyContent='center'>
              <Spinner />
            </Flex>
          );
        })()}
        {(() => {
          const product = mainProducts[6][isSubscription ? 'sub' : 'main'];
          const priceObject = product?.prices?.find((p) => p.currency === 'USD');
          const finalPrice = (priceObject?.finalPrice || 27.99) / 6;
          const price = (priceObject?.price || 27.99) / 6;
          const pricePerDay = Math.floor((finalPrice / 30) * 100) / 100;
          const isLoading = isSubmited && selectedPlan?.id === product?.id;

          return product ? (
            <Box
              position='relative'
              padding='1.5rem'
              borderRadius='1rem'
              border='1.5px solid'
              borderColor='black10'
              bg='#F4DCC61A'
              order={singleColumn ? 1 : { base: 1, md: 0 }}
            >
              <Flex
                flexDir={singleColumn ? 'row' : { base: 'row', md: 'column-reverse' }}
                marginBottom='1rem'
                gap='1rem'
              >
                <Box flex={1}>
                  <Flex
                    position={singleColumn ? 'static' : { base: 'static', md: 'absolute' }}
                    top='-12px'
                    left={0}
                    right={0}
                    marginBottom={singleColumn ? '0.5rem' : { base: '0.5rem', md: 0 }}
                    justifyContent={singleColumn ? 'initial' : { base: 'initial', md: 'center' }}
                  >
                    <Text
                      display='inline-block'
                      color='white'
                      fontSize={singleColumn ? '12px' : { base: '12px', md: '15px' }}
                      lineHeight='130%'
                      bg='error'
                      padding={singleColumn ? '3px 9px' : { base: '3px 9px', md: '4px 1rem' }}
                      borderRadius='0.5rem'
                    >
                      Best value SAVE {isSubscription ? '60%' : '50%'}
                    </Text>
                  </Flex>
                  <Flex alignItems='baseline' gap='6px'>
                    <Text lineHeight='100%' fontFamily='heading' fontSize='32px'>
                      {generalUtils.formatPrice(finalPrice, 'USD')}
                    </Text>
                    <Text fontSize='12px' color='black40'>
                      per bag
                    </Text>
                  </Flex>
                  <Text
                    marginBottom='0.5rem'
                    paddingBottom='0.5rem'
                    borderBottom='1px solid #F0EDEB'
                    color='error'
                    textDecor='line-through'
                  >
                    {generalUtils.formatPrice(price, 'USD')}
                  </Text>
                  <Flex flexDir='column' gap='0.25rem'>
                    <Flex alignItems='center' gap='0.5rem'>
                      <Flex>
                        <CheckSVG />
                      </Flex>
                      <Text fontSize='14px'>180 servings</Text>
                    </Flex>
                    <Flex alignItems='center' gap='0.5rem'>
                      <Flex>
                        <CheckSVG />
                      </Flex>
                      <Text fontSize='14px'>{`${generalUtils.formatPrice(pricePerDay, 'USD')} per day`}</Text>
                    </Flex>
                    <Flex alignItems='center' gap='0.5rem'>
                      <Flex>
                        <CheckSVG />
                      </Flex>
                      <Text fontSize='14px'>6 bags delivered</Text>
                    </Flex>
                  </Flex>
                </Box>
                <Box flex={1} textAlign='center'>
                  <Text fontWeight={500}>6-month supply</Text>
                  <Text fontSize='12px' color='#22222280'>
                    For achieving sustainable results
                  </Text>
                  <Flex
                    width='100%'
                    position='relative'
                    paddingBottom={singleColumn ? '70%' : { base: '70%', md: '90%' }}
                  >
                    <Image src={Product6Image} fill priority placeholder='blur' alt='' objectFit='contain' />
                  </Flex>
                </Box>
              </Flex>
              <Button
                variant='black'
                justifyContent={isLoading ? 'center' : 'space-between'}
                fontWeight={600}
                onClick={() => handleSelection(product)}
                marginBottom='1rem'
                isLoading={isLoading}
              >
                <div style={{ width: '11px' }} /> Order now <ArrowSVG />
              </Button>
              <Text fontSize='12px' textAlign='center'>
                {isSubscription ? 'Cancel anytime' : 'One time payment'}. Free shipping
              </Text>
            </Box>
          ) : (
            <Flex justifyContent='center'>
              <Spinner />
            </Flex>
          );
        })()}
      </Grid>
    </Flex>
  );
};

const ArrowSVG = () => (
  <>
    <svg width='11' height='18' viewBox='0 0 11 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M9.66797 9L1.66797 1' stroke='white' strokeWidth='1.5' strokeLinecap='round' />
      <path d='M9.66797 9L1.66797 17' stroke='white' strokeWidth='1.5' strokeLinecap='round' />
    </svg>
  </>
);
