import { Box, Flex, Text } from '@chakra-ui/react';
import { PaymentProvider } from '@menesko/models-harmonia';
import { OrderInterface, PaymentElement, StripeWrapper } from '@shared/payments';

import { config } from '~/config';
import { useAppLead } from '~/hooks/useAppLead';
import { useAppRouter } from '~/hooks/useAppRouter';
import { useCheckout } from '~/hooks/useCheckout';
import { AppRoute } from '~/types';

export const CheckoutSplitForm = () => {
  const { id, selectedPlan, updateLead } = useAppLead();
  const { createOrder, trackPurchase, finalPrice, orderNumberRef } = useCheckout();
  const { pushNextRoute, push } = useAppRouter(AppRoute.ORDER);
  const isAutorefund = selectedPlan?.isAutorefund;

  const handlePaymentSuccess = (order: OrderInterface) => {
    trackPurchase(String(selectedPlan?.id), { method: order?.method });

    const orderNumber = orderNumberRef.current;

    updateLead({
      paymentProvider: order.paymentProvider as PaymentProvider,
      orderNumber,
    });

    if (isAutorefund) {
      push(AppRoute.ERROR);
    } else {
      pushNextRoute({ orderNumber });
    }
  };

  return (
    <StripeWrapper apiKey={config.STRIPE.API_KEY}>
      <PaymentElement.Paypal
        createOrder={createOrder}
        forcePPRerender={[id]}
        onSuccess={handlePaymentSuccess}
        options={{ isSubscription: selectedPlan?.isSubscription }}
      />
      <Box marginTop='0.5rem'>
        <PaymentElement.PaymentButtons
          createOrder={createOrder}
          onSuccess={handlePaymentSuccess}
          options={{
            currency: 'USD',
            amount: finalPrice,
            title: selectedPlan ? `${selectedPlan.title}` : undefined,
          }}
        />
      </Box>
      <Flex alignItems='center' gap='1rem' marginY='0.75rem'>
        <Box flex={1} height='1px' bg='rgba(0, 0, 0, 0.1)' />
        <Text fontSize='12px' fontWeight={400} textAlign='center'>
          OR CREDIT CARD
        </Text>
        <Box flex={1} height='1px' bg='rgba(0, 0, 0, 0.1)' />
      </Flex>
      <PaymentElement.StripeCustomForm createOrder={createOrder} onSuccess={handlePaymentSuccess} />
    </StripeWrapper>
  );
};
